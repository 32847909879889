* {
    margin: 0;
    padding: 0;
	overflow: hidden; /* Hide scrollbars */
}

html {
	height: 100%;
}

body {
	height: 100%;
	overflow: hidden;
    background: linear-gradient(to right, 
	#455073 0%, #455073 50%,
	#c0904d 50%, #c0904d 100%
	);
}

.box {
	background: linear-gradient(to right, 
	#455073 0%, #455073 50%,
	#c0904d 50%, #c0904d 100%
	);
	position: absolute;
	display: flex;
    align-items: center;
    justify-content: center;
	min-height: 100%;
	min-width: 100%;
	max-width: 100%;
	max-height: 100%;
}

.container {
	display: flex;
}

.half-left {
	text-align: center;
	color: #fff;
	padding: 10px;
	width: 20em;
	height: 30em;
	background: #48484A; 
	border-radius: 20px 00px 00px 20px;
	box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.half-right {
	text-align: center;
	display: fixed;
	color: 303030;
	padding: 10px;
	width: 20em;
	height: 30em;
	border-width: 10px;
	background: rgba(175, 234, 248, 0.2);
	border-radius: 0px 20px 20px 0px;
	box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.profile_picture {
	
	margin-left: auto;
  	margin-right: auto;
	width: 60%;
	height: auto;
	border-radius: 50%;
}

.profile_text {
	padding: 10px;
	overflow: hidden;
}

h1 {
	font-family: 'Montserrat', sans-serif;
	color: #fff;
}

.profile_text h1 {
	font-size: 2.0rem;
}

.linksList {
	margin-top: 0px;
}

li {
	list-style-type: none;
}

hr {
	left: 10%;
	position: relative;
	border: 1px solid rgb(217, 217, 217);
  	width: 80%;
  	box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.second {
	font-size: 1.55rem;
	line-height: 1.0em;
}

.first {
	font-size: 3.25rem;
	padding-left: 30px;
	padding-right: 30px;
	padding-top: 40px;
	overflow-wrap: break-word;
}

ul {
	margin: 20%;
}

.icon-title {
    display: flex;
    align-items: center;
}

.icon {
    margin-right: 10px;
}

.work-information {
    display: flex;
    justify-content: center;
}
  
.profile_details {
    font-size: 20px;
    text-align: center;
}
  
.content {
	padding-top: 5px;
    display: inline-block;
    text-align: left;
}

.icon-detail {
    display: flex;
    align-items: center;
}

.other_details {
    font-size: 15px;
    padding-top: 10px;
}
